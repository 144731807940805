var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-table"},[(_vm.show===true)?_c('table',{class:{
        'h-100 rtl': _vm.lang ==1,
        'h-100 ltr': _vm.lang ==2,
      }},[_c('tr',{staticClass:"titleTable"},[_vm._l((_vm.headers),function(header){return _c('th',{key:header.locale},[_vm._v(" "+_vm._s(_vm.$t(header.locale))+" "),_c('br'),(header.notation)?_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.$t(header.notation_locale) || header.notation_locale))]):_vm._e()])}),_vm._l((_vm.actions),function(action){return _c('th',{key:action.title,staticClass:"actions-td text-align-center",class:{
              'dont-show-reciept': _vm.company.payment_receipt_history_table === false,
            }},[_vm._v(" "+_vm._s(action.header_title)+" ")])})],2),_vm._l((_vm.visiblePages),function(item,index){return _c('tr',{key:index},[_vm._l((_vm.headers),function(i){return _c('td',{key:i.locale,class:{'amount-cell' : i.locale=='amount'}},[(i.locale=='amount')?_c('div',_vm._l((item[i.locale].split('-')),function(ele,i){return _c('div',{key:i,staticClass:"amount-div"},[_c('p',[_vm._v(_vm._s(ele.split(':')[1])+" ")]),_c('p',{staticClass:"text-secondary"},[_vm._v(_vm._s(ele.split(':')[0])+" ")])])}),0):_c('span',[_vm._v(" "+_vm._s(item[i.locale]))])])}),_vm._l((_vm.actions),function(action){return _c('td',{key:action.title,staticClass:"actions-td text-align-center",class:{
              'dont-show-reciept': _vm.company.payment_receipt_history_table === false,
            }},[(_vm.company.payment_receipt_history_table)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":action.navigateTo + '?receiptId=' + item.pk + '&date=' + item.transaction_date}},[_vm._v(" "+_vm._s(action.title)+" ")]):_vm._e()],1)})],2)})],2):_vm._e(),(_vm.showPager)?_c('div',{staticClass:"pagination-container"},[_c('ul',{staticClass:"pages"},[_c('li',{staticClass:"page shadow-sm rounded-lg mx-2 page-back",on:{"click":_vm.firstPage}},[_c('i',{staticClass:"fas fa-angle-double-left"})]),_c('li',{staticClass:"page shadow-sm rounded-lg mx-2 page-back",on:{"click":_vm.decreasePage}},[_c('i',{staticClass:"fas fa-angle-left"})]),_vm._l((_vm.pages),function(i){return _c('li',{key:i,class:{
            'page shadow-sm rounded-lg mx-2 active': _vm.currentPage === i,
            'page shadow-sm rounded-lg mx-2': _vm.currentPage !== i,
          },on:{"click":function($event){return _vm.goToPage(i)}}},[_vm._v(" "+_vm._s(i)+" ")])}),_c('li',{staticClass:"page shadow-sm rounded mx-2 page-forward",on:{"click":_vm.incrementPage}},[_c('i',{staticClass:"fas fa-angle-right"})]),_c('li',{staticClass:"page shadow-sm rounded mx-2 page-forward",on:{"click":_vm.lastPage}},[_c('i',{staticClass:"fas fa-angle-double-right"})])],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }